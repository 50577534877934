import React from "react"
import { Link } from "gatsby"

export default function Pager({pageData, pageType}) {
    const pagers = [] 
    for (let index = 1; index < pageData.numberOfPages + 1; index++) {
        pagers.push(
        index === 1 ?
       `/${pageType}/`:
       `/${pageType}/${index}`)
        // pagers.push({a: index,b: index+1})
    }
  return (
    <div className="pager">
        {pagers.map((e,index) => {
        if(index === pageData.pageNumber){
            return(
                <div key={index}>
                        <p>{index + 1}</p>
                </div>
            )
        }else{
            return(
                <div key={index}>
                    <Link to={e}>
                        {index + 1}
                    </Link>
                </div>
            )
        }

        })}
    </div>
  )
}
