import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
// import { Location } from '@reach/router';

import Layout from "../components/layout";
import Title from "../components/title";
import Pager from "../components/pager";

export default function NewsList({ data, pageContext }) {
  const pageData = {
    titleClass: "news",
    title: "ニュースリリース",
    discription: "",
    image: 3,
  };
  const seodata = {
    title: "AVIELAN NEWS",
    seo: {
      description: "アヴィエランのニュース",
      image: {
        url: data.datoCmsImage.topimage[3].url,
      },
    },
    slug: "news",
  };
  return (
    <Layout pagestyle={pageData} seo={seodata}>
      <Title titlestyle={pageData} />
      <div className="pankz">
        <Link to="/">トップ</Link>
        <p>ニュースリリース</p>
      </div>
      <div className="news_list">
        {data.allDatoCmsNews.nodes.map((e, index) => {
          const tagclass = "news_" + e.category.slug;
          if (e.url) {
            return (
              <div key={index} className={tagclass}>
                <span>{e.data}</span>
                <span>{e.category.title}</span>
                <span>{e.title}</span>
                <a href={e.url} target="_blank" rel="noreferrer">
                  詳細はこちら
                </a>
              </div>
            );
          } else {
            return (
              <div key={index} className={tagclass}>
                <span>{e.data}</span>
                <span>{e.category.title}</span>
                <span>{e.title}</span>
              </div>
            );
          }
        })}
      </div>
      <Pager pageData={pageContext} pageType="news"></Pager>
    </Layout>
  );
}

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allDatoCmsNews(skip: $skip, limit: $limit, sort: { order: DESC, fields: data }) {
      nodes {
        title
        data
        category {
          title
          slug
        }
        url
        meta {
          createdAt(formatString: "YYYY/MM/DD")
          updatedAt(formatString: "YYYY/MM/DD")
        }
      }
    }
    datoCmsImage {
      topimage {
        url
      }
    }
  }
`;
